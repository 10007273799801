@font-face {
  font-family: JMHTypewriter;
  /* src: url(./fonts/AbrilFatface-Regular.ttf); */
  src: url(./fonts/Mom.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: JMHTypewriter!important;
  background: #1e1e1e;
}

h1, h2, h3, h4{

  font-family: JMHTypewriter!important;
}

.load{
  opacity: 1!important;
      -webkit-transition: opacity 1s ease-in;
 -moz-transition: opacity 1s ease-in;
  -ms-transition: opacity 1s ease-in;
   -o-transition: opacity 1s ease-in;
      transition: opacity 1s ease-in;
}

#result{
  opacity: 0;
}

#snowflakeContainer {
  position: absolute;
  left: 0px;
  top: 0px;
}
.snowflake {
  padding-left: 15px;
  font-family: JMHTypewriter!important;
  font-size: 14px;
  line-height: 24px;
  position: fixed;
  color: #979797!important;
  user-select: none;
  z-index: 1000;
}
.snowflake:hover {
  cursor: default;
}